@import '../../../../scss/theme-bootstrap';

.site-header-formatter {
  height: $gnav-mobile-height;
  position: relative;
  @include breakpoint($landscape-up) {
    height: $gnav-height;
  }
  .gnav-offers-hidden &,
  &.site-header-formatter--sticky {
    height: $navbar-mobile-height;
    @include breakpoint($landscape-up) {
      height: $navbar-height;
    }
  }
  .content-block-sitewide-banner--enabled & {
    height: $gnav-mobile-height + $sitewide-banner-mobile-height;
    @include breakpoint($landscape-up) {
      height: $gnav-height + $sitewide-banner-height;
    }
  }
  .gnav-offers-hidden.content-block-sitewide-banner--enabled & {
    height: $navbar-mobile-height + $sitewide-banner-mobile-height;
    @include breakpoint($landscape-up) {
      height: $navbar-height + $sitewide-banner-height;
    }
  }
  &__inner {
    @include transition(transform 0.25s ease);
    background-color: $color-white;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: $gnav-fixed-z-index;
    .site-header-formatter--sticky & {
      z-index: $gnav-fixed-z-index + 1;
    }
    // Hide the sticky Nav when scrolling down.
    .site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transform(translateY(-100%));
      .active-utility-overlay & {
        @include transform(unset);
      }
    }
    .content-block-sitewide-banner--enabled.site-header-formatter--hidden.site-header-formatter--sticky & {
      transform: translateY(-100%) translateY(-#{$sitewide-banner-mobile-height});
      @include breakpoint($landscape-up) {
        transform: translateY(-100%) translateY(-#{$sitewide-banner-height});
      }
    }
    .content-block-sitewide-banner--enabled.site-header-formatter--sticky & {
      @include transform(translateY(-#{$sitewide-banner-mobile-height}));
      @include breakpoint($landscape-up) {
        @include transform(translateY(-#{$sitewide-banner-height}));
      }
    }
    .active-gnav .content-block-sitewide-banner--enabled.site-header-formatter--sticky & {
      @include breakpoint($medium-down) {
        @include transform(unset);
        transition: transform 0.1s;
      }
    }
    .active-utility-overlay & {
      @include breakpoint($landscape-up) {
        z-index: $gnav-fixed-z-index + 1;
      }
      &.site-header-formatter--sticky {
        @include breakpoint($landscape-up) {
          z-index: $gnav-fixed-z-index + 1;
        }
      }
    }
  }
  &__inner-content {
    @include breakpoint($landscape-up) {
      max-width: $gnav-max-width;
      margin: 0 auto;
      position: relative;
    }
  }
  &__top {
    @include transition(height 0.25s ease, opacity 0.25s ease);
    position: relative;
    height: 100%;
    opacity: 1;
    .header-offers-banner-hidden &,
    .site-header-formatter--sticky & {
      height: 0;
      opacity: 0;
    }
  }
  &__header {
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    width: 100%;
  }
  &__offers {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  }
  &__bar {
    @include swap_direction(margin, 0 $container-pad-small 0 $container-pad-small);
    align-items: center;
    background-color: $color-white;
    display: flex;
    height: $first-navbar-mobile-height;
    position: relative;
    @include breakpoint($landscape-up) {
      @include transition(height 0.25s ease);
      height: auto;
      display: block;
      margin: 0;
      padding: 0 40px;
    }
    .active-utility-overlay &,
    .active-gnav & {
      @include breakpoint($landscape-up) {
        border-bottom: 1px solid $color-light-gray;
      }
    }
    .site-header-formatter--sticky & {
      @include breakpoint($landscape-up) {
        height: $navbar-height + 20px;
      }
    }
  }
  &__second-bar {
    @include swap_direction(padding, 0 $container-pad-small 0 $container-pad-small);
    height: $second-navbar-mobile-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $color-white;
    position: relative;
    border-top: 1px solid $color-black;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__logo {
    display: block;
    #{$ldirection}: 0;
    position: absolute;
    @include breakpoint($landscape-up) {
      position: relative;
      margin-bottom: 3px;
      width: 125px;
    }
    svg {
      margin: 0 auto;
      width: 110px;
      @include breakpoint($landscape-up) {
        width: 125px;
      }
    }
  }
  &__sections-logo {
    background-color: $color-white;
    display: block;
    position: fixed;
    padding-top: 22px;
    width: 75%;
    height: $gnav-mobile-link-height;
    z-index: 22;
    @include breakpoint($landscape-up) {
      display: none;
    }
    svg {
      margin: 0;
      height: 30px;
      width: 110px;
    }
  }
  // Mobile menu trigger (Hamburger).
  &__menu-icon {
    cursor: pointer;
    clear: none;
    @include breakpoint($landscape-up) {
      display: none;
    }
    svg {
      height: 22px;
      width: 22px;
    }
    label {
      display: block;
    }
  }
  &__quick-links {
    display: flex;
    align-items: center;
  }
  &__quick-link {
    font-family: $font--label;
    font-size: 10px;
    margin-#{$ldirection}: 10px;
  }
  // Only displays for mobile, takes user back to main sections list.
  &__mobile-menu-header {
    cursor: pointer;
    display: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    span {
      display: inline-block;
      font-size: 20px;
      letter-spacing: normal;
      text-transform: none;
    }
    &-label {
      display: block;
      line-height: unset;
    }
    .header-nav-section__trigger:checked ~ & {
      display: block;
      font-weight: 700;
      height: $gnav-mobile-link-height;
      line-height: $gnav-mobile-link-height;
      margin: 0;
      position: absolute;
      top: 55px;
      width: 100%;
      background-color: $color-white;
      z-index: 22;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__links {
    @include breakpoint($landscape-up) {
      display: none;
    }
    &-link {
      font-family: $font--text-main;
      width: 100%;
      font-size: 16px;
      @include breakpoint($landscape-up) {
        font-family: $font--label;
        letter-spacing: inherit;
      }
      a {
        text-decoration: none;
      }
    }
  }
  &__links-container {
    gap: 12px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-light-gray;
    padding: 24px 0 40px;
    @include breakpoint($landscape-up) {
      padding: 0;
      display: none;
    }
  }
  &__background {
    @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
    background-color: $color-white;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: $gnav-fixed-z-index - 1;
    @include breakpoint($landscape-up) {
      top: $gnav-height;
    }
    .site-header-formatter__trigger-reset:checked ~ .site-header-formatter__sections & {
      @include breakpoint($landscape-up) {
        opacity: 0;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked ~ & {
      @include breakpoint($landscape-up) {
        height: $gnav-content-max-height;
        opacity: 1;
        visibility: visible;
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $navbar-height;
      }
    }
    .content-block-sitewide-banner--enabled & {
      @include breakpoint($landscape-up) {
        top: $gnav-height + $sitewide-banner-height;
      }
    }
    .content-block-sitewide-banner--enabled.site-header-formatter--sticky &,
    .content-block-sitewide-banner--enabled.gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $navbar-height + $sitewide-banner-height;
      }
    }
  }
  &__sections {
    display: none;
    @include breakpoint($landscape-up) {
      padding: 0;
      #{$ldirection}: 0;
      margin: 0 0 14px;
      display: block;
      position: relative;
      height: 33%;
    }
    .site-header-formatter__mobile-trigger:not(:checked) ~ & {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
    .site-header-formatter__mobile-trigger:checked ~ & {
      background-color: $color-white;
      height: 100%;
      position: fixed;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 $gnav-mobile-hpad--outer;
      z-index: $gnav-fixed-z-index;
      @include breakpoint($landscape-up) {
        position: relative;
        width: inherit;
        background-color: unset;
        padding: 0;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ & {
      @include breakpoint($landscape-up) {
        background-color: $color-white;
        z-index: unset;
        display: block;
        overflow-y: inherit;
        position: relative;
        top: auto;
      }
    }
  }
  &__sections-main-links {
    font-family: $font--pretendard-semi-bold;
    padding-top: $gnav-mobile-link-height;
    @include breakpoint($landscape-up) {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0;
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    fill: $color-black;
    opacity: 1;
    position: relative;
    width: $gnav-icon--inline;
    height: $gnav-icon--inline;
    margin-bottom: 7px;
    @include breakpoint($landscape-up) {
      background: none;
      display: none;
    }
    &:hover,
    &:active {
      fill: $color-black;
    }
  }
  &--close-icon {
    &,
    input[type='radio'] ~ & {
      position: fixed;
      top: 24px;
      #{$rdirection}: 20px;
      padding: 0;
      z-index: 2;
      @include breakpoint($landscape-up) {
        display: none;
      }
      &::before {
        display: none;
      }
      .icon {
        width: 17px;
        height: 17px;
      }
    }
    input[type='radio']:checked ~ & {
      &::after {
        display: none;
      }
    }
  }

  &__first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }

  // Utility styles
  &__utilities {
    clear: none;
    #{$rdirection}: 0;
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
    position: absolute;
    display: flex;
    align-items: center;
    @include breakpoint($landscape-up) {
      position: relative;
      width: auto;
    }
    &-item {
      display: inline-block;
      padding: 0 5px;
      &:last-child {
        padding-#{$rdirection}: 0;
      }
      @include breakpoint($landscape-up) {
        padding: 0 8px;
      }
    }
    .site-header-formatter__camera {
      padding-#{$rdirection}: 10px;
      @include breakpoint($medium-up) {
        display: none;
        padding: 0 5px;
      }
    }
    .gnav-util {
      &__content {
        position: fixed;
        background: $white;
        border: 1px solid $color-light-gray;
        padding: 0 25px;
        width: 100%;
        z-index: 999;
        height: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow-y: auto;
        top: 0;
        #{$rdirection}: -100%;
        visibility: hidden;
        @include breakpoint($landscape-up) {
          padding: 0 60px;
          width: 50%;
        }
        &-header-title {
          text-align: $ldirection;
          padding: 0 0 20px;
          line-height: 1;
          border-bottom: 1px solid $color-light-gray;
        }
        &-header {
          padding: 10px 0 0;
          &-close {
            display: inline-block;
            text-align: $rdirection;
            width: 100%;
            height: 45px;
            .icon--close {
              width: 15px;
              height: 15px;
              margin: 5px;
              display: inline-block;
              stroke: $color-light-gray;
              stroke-width: 35px;
              fill: $color-light-gray;
            }
          }
          .cart-loading & {
            padding: 10px 0 15px;
          }
        }
        .site-header-formatter--sticky.content-block-sitewide-banner--enabled & {
          height: 100vh;
          top: $sitewide-banner-mobile-height;
          @include breakpoint($landscape-up) {
            top: $sitewide-banner-height;
          }
        }
        .site-header-formatter--hidden.site-header-formatter--sticky.content-block-sitewide-banner--enabled & {
          top: $gnav-content-max-height - $navbar-mobile-height - 20px;
          @include breakpoint($landscape-up) {
            top: $gnav-height + $sitewide-banner-height - 6px;
          }
        }
      }
      &-nav-section-toggle {
        display: inline-block;
      }
      &__icon {
        font-family: $font--label;
        font-size: 10px;
        color: $color-black;
        position: relative;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint($landscape-up) {
          font-size: 12px;
        }
        .icon {
          width: 25px;
          height: 25px;
          &:before {
            font-size: 30px;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
      &__icon-account {
        &:hover {
          color: $color-black;
          text-decoration: underline;
          text-underline-offset: $gnav-underline-offset;
        }
      }
      &-trigger:checked ~ .gnav-util__content {
        transition: $rdirection 0.3s ease-in-out, width 0.3s ease-in-out;
        display: flex;
        #{$rdirection}: 0;
        visibility: visible;
      }
      &-trigger-label {
        padding: 0;
        @include breakpoint($landscape-up) {
          padding: 10px 0 0;
        }
        &:before,
        &:after {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
    .contextual-links-region {
      position: initial;
    }
    // @todo Add styles for email sign up and search in this section
  }

  // Shared overlay for the gnav and utility
  &-overlay {
    &,
    input[type='radio'] ~ & {
      background-color: $color-white;
      cursor: pointer;
      display: none;
      height: 0;
      #{$ldirection}: 0;
      opacity: 0;
      position: fixed;
      top: 0;
      transition: visibility 0.3s, opacity 0.3s;
      visibility: hidden;
      width: 100%;
      z-index: $gnav-fixed-z-index - 2;
      &::after,
      &::before {
        display: none;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked + .header-nav-section__section + &,
    .gnav-util-trigger:checked + & {
      height: 100%;
      opacity: 0.5;
      visibility: visible;
      @include breakpoint($landscape-up) {
        display: none;
      }
      .site-header-formatter__utilities & {
        @include breakpoint($landscape-up) {
          top: 0;
        }
      }
      .content-block-sitewide-banner--enabled & {
        height: 100vh;
        top: $gnav-mobile-height + $sitewide-banner-mobile-height;
        @include breakpoint($landscape-up) {
          top: $gnav-height + $sitewide-banner-height;
        }
      }
      .content-block-sitewide-banner--enabled.gnav-offers-hidden &,
      .content-block-sitewide-banner--enabled.site-header-formatter--sticky & {
        height: 100vh;
        top: $navbar-mobile-height + $sitewide-banner-mobile-height;
        @include breakpoint($landscape-up) {
          top: $navbar-height + $sitewide-banner-height;
        }
      }
    }
    // Open dark overlay for mobile. The previous section works only for PC
    .gnav-util-trigger:checked + & {
      display: block;
      top: $gnav-mobile-height;
      padding: 0;
      @include breakpoint($landscape-up) {
        top: $gnav-height;
      }
    }
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $navbar-height;
      }
    }
    .site-header-formatter--sticky & {
      @include breakpoint($landscape-up) {
        top: $navbar-height - 20px;
      }
    }
    .content-block-sitewide-banner--enabled.gnav-offers-hidden & {
      top: $navbar-mobile-height + $sitewide-banner-mobile-height;
      @include breakpoint($landscape-up) {
        top: $navbar-height + $sitewide-banner-height;
      }
    }
  }
}

// Overrides for specific sections and states applied to html and body.
html.active-gnav {
  overflow: hidden; // No scrolling the body when fixed nav sections are active.
  overflow-y: hidden; // ios safari.
  @include breakpoint($landscape-up) {
    overflow: unset;
  }
  .site-header-formatter--hidden.site-header-formatter--sticky .site-header-formatter__inner {
    @include transform(unset);
  }
  .content-block-sitewide-banner--enabled.site-header-formatter--hidden.site-header-formatter--sticky
    .site-header-formatter__inner {
    @include transform(translateY(-70px));
  }
}

body {
  .active-gnav & {
    max-width: 100%;
    overflow: hidden; // No scrolling the body when fixed nav sections are active.
    overflow-y: hidden; // ios safari.
    width: 100%;
    position: relative;
    @include breakpoint($landscape-up) {
      overflow: unset;
    }
  }
}
// Minimal nav class applied via elc_gnav_minimal_v1 for drupal.
// Class is on body for drupal, may be on html for pg pages.
.elc-gnav-minimal {
  .site-header-formatter__top,
  .site-header-formatter__menu-icon,
  .site-header-formatter__utilities,
  .site-header-formatter__header .site-header-formatter__sections {
    display: none;
  }
  .site-header-formatter__sections .site-header-formatter__mobile-trigger:not(:checked) ~ & {
    @include breakpoint($landscape-up) {
      .elc-gnav-minimal & {
        display: none;
      }
    }
  }
}
